import React from "react";
import { useTranslation } from "react-i18next";
import mockup1 from "../images/slider/mockup1.png";
import mockup2 from "../images/slider/mockup2.png";
import mockup3 from "../images/slider/mockup3.png";
import mockup4 from "../images/slider/mockup4.png";
import mockup5 from "../images/slider/mockup5.png";
import mockup6 from "../images/slider/mockup6.png";

const Main = () => {
  const screens = [mockup1, mockup2, mockup3, mockup4, mockup5, mockup6];
  const { t } = useTranslation();

  return (
    <section className="main">
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-6 col-md-6 col-sm-12 py-3 caption">
            <div className="caption-container">
              <h1 className="main-heading caption-title">
                {t("slider-title2")}
              </h1>
              <p className="paragraph caption-text">{t("slider-text2")}</p>
              <div className="tutipay-btn caption-btn">
                <a href="#contact">{t("slider-btn1")}</a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="screens-slider">
              {screens.map((screen, index) => (
                <div key={screen} className={"mockup" + (index + 1)}>
                  <img src={screen} width="600" height="auto" alt="tutipay-app-screen" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
