import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Footer, Header } from "../components";

const TermsOfUse = () => {
    const { t } = useTranslation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Header />
            <section className="terms-of-use">
                <div className="container">
                    <div className="section-heading mb-5">
                        <h2>
                            {t('terms-t1')}
                        </h2>
                    </div>
                    <li className="paragraph">{t('terms-p1')}</li>
                    <li className="paragraph">{t('terms-p2')}.</li>
                    <li className="paragraph">{t('terms-p3')}</li>
                    <li className="paragraph">{t('terms-p4')}</li>
                    <h5>{t('terms-t2')}</h5>
                    <p className="paragraph">{t('terms-p5')}</p>
                    <h3>{t('terms-t3')}</h3>
                    <p className="paragraph">{t('terms-p6')}</p>
                    <h3>{t('terms-t4')}</h3>
                    <p className="paragraph">{t('terms-p7')}</p>
                    <p className="paragraph">
                    {t('terms-p8')}
                    </p>
                    <h3>{t('terms-t5')}</h3>
                        <p className="paragraph">{t('terms-p9')}</p>
                        <p className="paragraph">{t('terms-p10')}</p>
                    <ul className="px-3">
                        <li className="paragraph">{t('terms-p11')}</li>
                        <li className="paragraph">{t('terms-p12')}</li>
                        <li className="paragraph">{t('terms-p13')}</li>
                        <li className="paragraph">{t('terms-p14')}</li>
                        <li className="paragraph">{t('terms-p15')}</li>
                        <li className="paragraph">{t('terms-p16')}</li>
                        <li className="paragraph">{t('terms-p17')}</li>
                        <li className="paragraph">{t('terms-p18')}</li>
                        <li className="paragraph">{t('terms-p19')}</li>
                        <li className="paragraph">{t('terms-p20')}</li>
                        <li className="paragraph">{t('terms-p21')}</li>
                        <li className="paragraph">{t('terms-p22')}</li>
                        <li className="paragraph">{t('terms-p23')}</li>
                        <li className="paragraph">{t('terms-p24')}</li>
                    </ul>
                    <h3>{t('terms-t6')}</h3>
                    <p className="paragraph">{t('terms-p25')}</p>
                    <p className="paragraph">{t('terms-p26')}</p>
                    <p className="paragraph">{t('terms-p27')}</p>
                    <p className="paragraph">{t('terms-p28')}</p>
                    <ul className="px-3">
                        <li className="paragraph">{t('terms-p29')}</li>
                        <li className="paragraph">{t('terms-p30')}</li>
                        <li className="paragraph">{t('terms-p31')}</li>
                        <li className="paragraph">{t('terms-p32')}</li>
                        <li className="paragraph">{t('terms-p33')}</li>
                        <li className="paragraph">{t('terms-p34')}</li>
                        </ul>
                        <p className="paragraph">{t('terms-p35')}</p>
                        <p className="paragraph">{t('terms-p36')}</p>
                        <h3>{t('terms-t7')}</h3>
                        <p className="paragraph">{t('terms-p37')}</p>
                        <p className="paragraph">{t('terms-p38')}</p>
                        <p className="paragraph">{t('terms-p39')}</p>
                        <h3>{t('terms-t8')}</h3>
                        <p className="paragraph">{t('terms-p40')}</p>
                        <h3>{t('terms-t9')}</h3>
                        <p className="paragraph">{t('terms-p41')}</p>
                        <h3>{t('terms-t10')}</h3>
                        <p className="paragraph">{t('terms-p42')}</p>
                        <h3>{t('terms-t11')}</h3>
                        <p className="paragraph">{t('terms-p43')}</p>
                        <h3>{t('terms-t12')}</h3>
                        <p className="paragraph">{t('terms-p44')}</p>
                        <p className="paragraph">{t('terms-p45')}</p>
                        <p className="paragraph">{t('terms-p46')}</p>
                        <p className="paragraph">{t('terms-p47')}</p>
                        <h3>{t('terms-t13')}</h3>
                        <p className="paragraph">{t('terms-p48')}</p>
                        <h3>{t('terms-t14')}</h3>
                        <p className="paragraph">{t('terms-p49')}</p>
                        <p className="paragraph">{t('terms-p50')}</p>
                        <p className="paragraph">{t('terms-p51')}</p>
                        <h3>{t('terms-t15')}</h3>
                        <p className="paragraph">{t('terms-p52')}</p>
                        <h3>{t('terms-t16')}</h3>
                        <p className="paragraph">{t('terms-p53')}</p>
                        <p className="paragraph">{t('terms-p54')}</p>
                        <p className="paragraph">{t('terms-p55')}</p>
                        <p className="paragraph">{t('terms-p56')}</p>
                        <p className="paragraph">{t('terms-p57')}</p>
                </div>
            </section>
            <Footer/>
        </>
    );
}

export default TermsOfUse;