import React from "react";
import Img from "../images/transactions.webp";
import { useTranslation } from "react-i18next";

const Transactions = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="transactions-section pt-5 pb-0">
        <div className="container detailed-service-section">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-6 text-center">
              <img src={Img} width="500" height="405" alt="transfer money" />
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="bannar-caption">
                <div className="section-heading mb-3">
                  <h2>{t("transactions-title")}</h2>
                </div>
                <p className="paragraph">{t("transactions-text")}.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Transactions;
