import React, { useContext } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { langContext } from "../context/LangContext";
import logo from "../images/white-logo.png";
const Header = () => {

  const { t , i18n } = useTranslation();
  const {lng, setLng} = useContext(langContext);
  useEffect(() => {
    i18n.changeLanguage(lng);
  }, [lng]);
  return (
    <header className="mx-auto text-center">
      <nav className="navbar justify-content-between navbar-expand-md py-3">
        <div className="container px-3">
          <NavLink to="/" className="navbar-brand">
            <img src={logo} className="logo" alt="tutipay-logo" width="150" height="55" />
          </NavLink>
          <button
            className="btn btn-light shadow-none"
            onClick={()=> setLng(lng === 'en'? 'ar' : 'en')}
          >
            {t("lang")}
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
