import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import Logo from "../images/logo.png";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer>
        <div className="upper-footer">
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                <div className="mb-4">
                  <img src={Logo} width="144" height="33" alt="tutipay-logo" />
                </div>
                <p className="paragraph">{t("footer-text")}.</p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                <h4 className="footer-header ">{t("footer-about")}</h4>
                <p>{t("footer-about1")}. </p>
                <p>{t("footer-about2")}.</p>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                <h4 className="footer-header ">{t("footer-contact")}</h4>
                <div className="mb-2">
                <a className="icon sm-icon me-2" href="https://github.com/tutipay" target="_blank" rel="noopener noreferrer">
                  <i className="bx bxl-github"></i>
                <span className="sr-only d-none">github</span>
                </a>
                <a className="icon sm-icon me-2" href="https://facebook.com/tutipay" target="_blank" rel="noopener noreferrer">
                  <i className="bx bxl-facebook-circle"></i>
                  <span className="sr-only d-none">facebook</span>
                </a>
                <a className="icon sm-icon me-2" href=" https://www.linkedin.com/company/tutipay" target="_blank" rel="noopener noreferrer">
                  <i className="bx bxl-linkedin"></i>
                  <span className="sr-only d-none">linkedin</span>
                </a>
                <a className="icon sm-icon me-2" href="https://twitter.com/Tutipay1" target="_blank" rel="noopener noreferrer">
                  <i className="bx bxl-twitter"></i>
                  <span className="sr-only d-none">twitter</span>
                </a>
                <a className="icon sm-icon me-2" href="https://www.tiktok.com/@tutipay1?lang=en" target="_blank" rel="noopener noreferrer">
                  <i className="bx bxl-tiktok"></i>
                  <span className="sr-only d-none">tiktok</span>
                </a>
                {/* <a className="icon sm-icon me-2" href="https://www.youtube.com/channel/UCxoutPt_ggousvKI-YqBn-Q" target="_blank" rel="noopener noreferrer">
                  <i className="bx bxl-youtube"></i>
                   <span className="sr-only d-none">youtube</span>
                </a> */}
                </div>
                <div>
                  <p className="phone-number d-flex align-items-center">
                    <i className="bx bx-phone icon me-2"></i>
                    <span> +2490155211121</span>
                  </p>
                </div>
                <div>
                  <p className="phone-number d-flex align-items-center">
                    <i className="bx bx-envelope icon me-2"></i>
                      info@tutipay.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='morelinks'>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-md-4 px-2 py-1">
                <Link to="/privacy">{t("footer-privacy")}</Link>
              </div>
              <div className="col-md-4 px-2 py-1">
                <Link to="/terms">{t("footer-terms")}</Link>
              </div>
              <div className="col-md-4 px-2 py-1">
                <Link to="#">{t("footer-faqs")}</Link>
              </div>
            </div>
            </div>
            </div>
        <div className="copy-right">
          <div className="container">
            <div className="row d-flex">
              <div className="col-lg-6 col-sm-12 text-center">
                <p className="paragraph m-0 py-2">
                  {t("footer-rights")} &copy; <span>tutipay</span>
                </p>
              </div>
              <div className="col-lg-6 col-sm-12 text-center">
                <p className="paragraph made-in-sudan m-0 py-2">
                  {t("footer-made-in")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
