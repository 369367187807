import React from "react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import HCaptcha from "@hcaptcha/react-hcaptcha";
import axios from "../api/axios";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const Signin_URL = "/waiting";
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  // const [errMsg, setErrMsg] = useState("");
  // const [btnAllowed, setBtnAllowed] = useState(false);
  // const [hcaptchaKey, setKey] = useState("");
  const { t } = useTranslation();
  const success = document.getElementById("alert-success");
  const failed = document.getElementById("alert-danger");

  // form submit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const reponse = await axios.post(
        Signin_URL,
        JSON.stringify({ name, email, mobile }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(JSON.stringify(reponse));
      setName("");
      setEmail("");
      setMobile("");
      success.classList.add("show");
      setTimeout(function() {
        success.classList.remove("show");
      }, 4000);
    } catch (err) {
      // if (!err?.reponse) {
      //     setErrCode(err.response.data.code);
      //     setErrMsg(err.response.data.message);
      // }
      failed.classList.add("show");
      setTimeout(function() {
        failed.classList.remove("show");
      }, 4000);
    }
  };
  return (
    <section className="contactus-section pb-0" id="contact">
      <div className="container mx-auto text-center">
        <div className="section-heading ">
          <h2>{t("contact-title")}!</h2>
        </div>
        <p className="paragraph" style={{ color: "#ddd" }}>
          {t("contact-text")}
        </p>
        <form method="POST" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
              <input
                className="mb-1 px-2 py-2"
                name="name"
                onChange={(e) => setName(e.target.value.toLowerCase())}
                value={name}
                type="text"
                placeholder={t("name")}
                required
              />
              {/* <span className="text-red-600 text-xs error-check">
              Enter a valid name!
            </span> */}
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
              <input
                className="mb-1 px-2 py-2"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                type="email"
                placeholder={t("email")}
                required
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mb-3 d-flex justify-content-center">
              <div>
                <PhoneInput
                  country={"sd"}
                  value={mobile}
                  onChange={(mobile) => setMobile("+" + mobile)}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              {/* Make the button disabled if btnAllowed is not checked */}
              <button
                // disabled={!btnAllowed}
                className="mb-4 p-0 px-4 py-2 btn btn-outline-light"
              >
                {t("contact-btn")}
              </button>
            </div>
            {/* <HCaptcha
              className="p-0"
              style={{ padding: "0px" }}
              sitekey="75aa43fe-ece1-4dc8-9bbe-23f3ccc913df"
              onVerify={(token, ekey) => setBtnAllowed(true)}
            /> */}
            {/* <p className="text-xs text-black-500 mb-2 mx-3">{errMsg}</p> */}
          </div>
        </form>
      </div>
      <div
        className="alert alert-success alert-dismissible fade"
        id="alert-success"
      >
        <button
          type="button"
          className="btn-close shadow-none"
          data-bs-dismiss="alert"
          aria-label="close"
        ></button>
        {t("contact-success-msg")}
      </div>
      <div
        className="alert alert-danger alert-dismissible fade"
        id="alert-danger"
      >
        <button
          type="button"
          className="btn-close shadow-none"
          data-bs-dismiss="alert"
          aria-label="close"
        ></button>
        {t("contact-failed-msg")}
      </div>
    </section>
  );
};

export default Contact;
