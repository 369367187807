import React, { createContext, useEffect, useState } from "react";

export const langContext = createContext();

const langContextProvider = (props) => {
    const lang = localStorage.getItem('lang');
    const [lng, setLng] = useState(lang || 'en');
    useEffect(() => {
        localStorage.setItem('lang', lng);
    }, [lng]);

    return (
        <langContext.Provider value={{ lng, setLng }}>
            {props.children}
        </langContext.Provider>
    );
};

export default langContextProvider;
