import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import "./i18n-config";
import LangContextProvider from "./context/LangContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <LangContextProvider>
    <Router>
      <App />
    </Router>
    </LangContextProvider>
  </React.StrictMode>
);
