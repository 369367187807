import React from "react";
import {
  Agency,
  Bannar,
  Contact,
  Footer,
  Header,
  Joinus,
  Main,
  Transactions,
  Whyus,
} from "../components";

const Home = () => {
  return (
    <>
      <Header />
      <Main />
      <Whyus />
      <Joinus />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Transactions />
          </div>
          <div className="col-lg-6">
            <Agency />
          </div>
        </div>
      </div>
      <Bannar />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
