import React from 'react';
import { useTranslation } from "react-i18next";
import { ReactComponent as Icon1 } from "../images/icons/i1.svg";
import { ReactComponent as Icon2 } from "../images/icons/i2.svg";
import { ReactComponent as Icon3 } from "../images/icons/i3.svg";
import { ReactComponent as Icon4 } from "../images/icons/i4.svg";

const Whyus = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="whyus-section">
        <div className="container">
          <div className="section-heading">
            <h2>{t("features-title")}</h2>
          </div>
          <p className="paragraph mb-5 mt-3">{t("1-click")}</p>
          <div className="row mx-auto justify-content-center">
            <div className="col-lg-6 col-md-6 col-xs-12 mb-3">
              <div className="service-card text-center">
                <div className="card-icon d-flex align-items-center justify-content-center">
                  <Icon1 style={{ width: "100px", fill: '#0b244b' }} />
                </div>
                <h4 className="card-title">{t("features-title1")}</h4>
                <p className="paragraph">{t("features-text1")}.</p>
                <Icon1 className="bg-icon" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 mb-3">
              <div className="service-card text-center">
                <div className="card-icon d-flex align-items-center justify-content-center">
                  <Icon2 style={{ width: "80px", fill: '#0b244b' }} />
                </div>
                <h4 className="card-title">{t("features-title2")}</h4>
                <p className="paragraph">{t("features-text2")}.</p>
                <Icon2 className="bg-icon" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 mb-3">
              <div className="service-card text-center">
                <div className="card-icon d-flex align-items-center justify-content-center">
                  <Icon3 style={{ width: "70px", fill: '#0b244b' }} />
                </div>
                <h4 className="card-title">{t("features-title3")}</h4>
                <p className="paragraph">{t("features-text3")}.</p>
                <Icon3 className="bg-icon" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12 mb-3">
              <div className="service-card text-center">
                <div className="card-icon d-flex align-items-center justify-content-center">
                  <Icon4 style={{ width: "75px", fill: '#0b244b' }} />
                </div>
                <h4 className="card-title">{t("features-title4")}</h4>
                <p className="paragraph">{t("features-text4")}.</p>
                <Icon4 className="bg-icon" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Whyus;
