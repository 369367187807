import React from "react";
import BannarImg from "../images/bannar2.webp";
import { useTranslation } from "react-i18next";

const Bannar = () => {
  const { t } = useTranslation();
  return (
    <section className="bannar-section py-5">
      <div className="container detailed-service-section">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img src={BannarImg} width="500" height="408" alt="expolore all online services" />
          </div>
          <div className="col-md-6">
            <div className="bannar-caption">
              <div className="section-heading mb-3">
                <h2>{t("onlineServices-title")}!</h2>
              </div>
              <p className="paragraph">{t("onlineServices-text")}.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bannar;
