import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import { useContext } from "react";
import { langContext } from "./context/LangContext";

function App() {
  const {lng} = useContext(langContext);
  return (
    <div className={lng === 'ar'? "App ar" : "App"}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="*" element={<div>404 Not Found!</div>} />
      </Routes>
    </div>
  );
}

export default App;
