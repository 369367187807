import React from "react";
import Img from "../images/slider-mockup.webp";
import { useTranslation } from "react-i18next";

const Joinus = () => {
  const { t } = useTranslation();

  return (
    <section className="joinus-section py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 order-sm-1">
            <img src={Img} width="600" height="436" alt="tutipay app services" />
          </div>
          <div className="col-md-6">
            <div className="bannar-caption">
              <div className="section-heading mb-3">
                <h2>{t("joinus-title")}</h2>
              </div>
              <p className="paragraph">{t("joinus-text")}!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Joinus;
