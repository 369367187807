import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Footer, Header } from "../components";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Header />
            <section className="privacy-policy">
                <div className="container">
                    <div className="section-heading mb-5">
                        <h2>
                            {t('privacy-title1')}
                        </h2>
                    </div>
                    <li className="paragraph">{t('privacy-p1')}</li>
                    <li className="paragraph">{t('privacy-p2')}.</li>
                    <li className="paragraph">{t('privacy-p3')}</li>
                    <li className="paragraph">{t('privacy-p4')}</li>
                    <li className="paragraph">{t('privacy-p5')}</li>
                    <li className="paragraph">{t('privacy-p6')}</li>
                    <h3>{t('privacy-title2')}</h3>
                    <p className="paragraph">{t('privacy-p7')}</p>
                    <h5>{t('privacy-title3')}</h5>
                    <p className="paragraph">
                    {t('privacy-p8')}
                    </p>
                    <ul className="px-3">
                        <li className="paragraph">{t('privacy-p9')}</li>
                        <li className="paragraph">{t('privacy-p10')}</li>
                        <li className="paragraph">{t('privacy-p11')}</li>
                    </ul>
                    <p className="paragraph">{t('privacy-p12')}</p>
                    <h5>{t('privacy-title4')}</h5>
                    <p className="paragraph">{t('privacy-p13')}</p>
                    <h5>{t('privacy-title5')}</h5>
                    <p className="paragraph">{t('privacy-p14')}</p>
                    <h3>{t('privacy-title6')}</h3>
                    <h5>{t('privacy-title7')}</h5>
                    <p className="paragraph">{t('privacy-p15')}</p>
                    <p className="paragraph">{t('privacy-p16')}</p>
                    <h5>{t('privacy-title8')}</h5>
                    <p className="paragraph">{t('privacy-p17')}</p>
                    <p className="paragraph">{t('privacy-p18')}</p>
                    <h3>{t('privacy-title9')}</h3>
                    <p className="paragraph">{t('privacy-p19')}</p>
                    <h3>{t('privacy-title10')}</h3>
                    <p className="paragraph">{t('privacy-p20')}</p>
                    <h3>{t('privacy-title11')}</h3>
                    <p className="paragraph">
                    {t('privacy-p21')}
                    </p>
                    <h3>{t('privacy-title12')}</h3>
                    <p className="paragraph">{t('privacy-p22')}</p>
                    <h3>{t('privacy-title13')}</h3>
                    <p className="paragraph">
                    {t('privacy-p23')}
                    </p>
                    <p className="paragraph">{t('privacy-p24')}</p>

                </div>
            </section>
            <Footer/>
        </>
    );
}

export default PrivacyPolicy;